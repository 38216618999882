/** font family **/
@media only screen and (min-width: 768px) {
  /*! "$not-mobile" */
}
@media only screen and (min-width: 901px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (min-width: 1025px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (max-width: 1599px) {
  /*! "until-dt-max" */
}
@media only screen and (max-width: 1366px) {
  /*! "until-dt-med" */
}
@media only screen and (max-width: 1279px) {
  /*! "until-dt-min" */
}
@media only screen and (min-width: 1280px) {
  /*! "dt-min" */
}
@media only screen and (max-width: 1024px) {
  /*! "tablet-max" */
}
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  /*! "tablet-ls" */
}
@media only screen and (max-width: 900px) {
  /*! "mobile-tablet-pt" */
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  /*! "tablet-pt" */
}
@media only screen and (max-width: 767px) {
  /*! "$mobile-max" */
}
@media only screen and (max-width: 415px) {
  /*! "$mobile-pt" */
}
:root {
  --swiper-navigation-size:44px;
}

.swiper-button-next, .swiper-button-prev {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - var(--swiper-navigation-size) / 2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-next.swiper-button-hidden, .swiper-button-prev.swiper-button-hidden {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

.swiper-navigation-disabled .swiper-button-next, .swiper-navigation-disabled .swiper-button-prev {
  display: none !important;
}

.swiper-button-next svg, .swiper-button-prev svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform-origin: center;
}

.swiper-rtl .swiper-button-next svg, .swiper-rtl .swiper-button-prev svg {
  transform: rotate(180deg);
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 10px);
  right: auto;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 10px);
  left: auto;
}

.swiper-button-lock {
  display: none;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: "prev";
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 10px);
  left: auto;
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: "next";
}

.video-block {
  overflow: hidden;
  width: 100%;
}
.video-block .video-wrapper {
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 1440px;
  padding: 70px 16px;
  width: 100%;
}
@media only screen and (min-width: 1025px) {
  .video-block .video-wrapper {
    padding: 100px 16px;
  }
  .video-block .video-wrapper.video-left {
    justify-content: flex-start;
  }
  .video-block .video-wrapper.video-right {
    justify-content: flex-end;
  }
}
.video-block .video-inner {
  width: 100%;
}
.video-block .vimeo-video,
.video-block .youtube-video {
  position: relative;
  padding: 56.25% 0 0;
}
.video-block .youtube-video {
  overflow: hidden;
  position: relative;
}
.video-block .youtube-video iframe {
  height: 100%;
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.video-block .custom-video video {
  max-width: 100%;
}
@media only screen and (max-width: 1024px) {
  .video-block .custom-video video {
    height: auto;
  }
}

.swiper-button-next::after,
.swiper-rtl .swiper-button-prev::after,
.swiper-button-prev::after,
.swiper-rtl .swiper-button-next::after {
  content: none;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: 50%;
  background-repeat: no-repeat;
}

.swiper-container {
  position: relative;
  z-index: 1;
}

.work-at-container__slider {
  position: static;
}

.work-at-container {
  max-width: 1280px;
  padding-top: 80px;
  padding-bottom: 70px;
  margin: 0 auto;
}
@media only screen and (max-width: 1599px) {
  .work-at-container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (max-width: 1024px) {
  .work-at-container {
    padding-top: 80px;
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .work-at-container {
    position: relative;
    overflow: hidden;
  }
}
.work-at-container__block {
  display: flex;
  flex-wrap: wrap;
  margin-top: 56px;
}
@media only screen and (max-width: 767px) {
  .work-at-container__block {
    flex-wrap: nowrap;
    padding: 0;
    margin-top: 27px;
  }
}
@media only screen and (max-width: 767px) {
  .work-at-container__block .work-at-container__block-tile:last-child {
    margin-bottom: 33px;
  }
}
.work-at-container__block .work-at-container__block-tile:nth-child(4n+4) {
  margin-right: 0;
}
.work-at-container__block-tile {
  position: relative;
  flex: 0 0 24%;
  margin-right: 1.3%;
  margin-bottom: 30px;
}
@media only screen and (max-width: 1024px) {
  .work-at-container__block-tile {
    flex: 0 0 23%;
    margin-right: 2.65%;
  }
}
@media only screen and (max-width: 767px) {
  .work-at-container__block-tile {
    flex: none;
    margin-right: 0;
    margin-bottom: 35px;
  }
}
.work-at-container__link {
  background-color: #fe7000;
  color: #fff;
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-family: "HeadingProTreble-Heavy", sans-serif;
  display: block;
  text-align: center;
  padding: 14px 10px;
  width: 25%;
}
.work-at-container__link.center {
  margin: 25px auto 0;
}
@media only screen and (min-width: 768px) {
  .work-at-container__link.left {
    margin: 25px auto 0 25px;
  }
  .work-at-container__link.right {
    margin: 25px 25px 0 auto;
  }
}
@media only screen and (min-width: 1367px) {
  .work-at-container__link.left {
    margin: 25px auto 0 0;
  }
  .work-at-container__link.right {
    margin: 25px 0 0 auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .work-at-container__link {
    margin-top: 155px;
  }
}
@media only screen and (max-width: 767px) {
  .work-at-container__link {
    margin: 0 auto;
    width: 90%;
    max-width: 310px;
  }
}
.work-at-container__block-tile-content-container {
  background-color: #fff;
  position: relative;
  padding: 20px;
  margin-top: -154px;
  margin-left: auto;
  width: 72%;
}
@media only screen and (max-width: 1366px) {
  .work-at-container__block-tile-content-container {
    width: 92%;
  }
}
@media only screen and (min-width: 768px) {
  .work-at-container__block-tile-content-container {
    max-width: 220px;
  }
}
@media only screen and (max-width: 1024px) {
  .work-at-container__block-tile-content-container {
    width: 150px;
  }
}
@media only screen and (max-width: 767px) {
  .work-at-container__block-tile-content-container {
    padding: 20px 27px;
    margin-top: -137px;
    width: 92%;
  }
}
.work-at-container__block-tile-content-container-title {
  display: block;
  color: #2d2d2d;
  font-family: "HeadingProTreble-Heavy", sans-serif;
  font-size: 22px;
  line-height: 28px;
  text-transform: uppercase;
  margin: 13px 0 10px;
}
@media only screen and (max-width: 767px) {
  .work-at-container__block-tile-content-container-title {
    font-size: 20px;
    line-height: 26px;
  }
}
.work-at-container__block-tile-content-container-paragraph, .work-at-container__block-tile-content-container-link {
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-size: 16px;
  line-height: 28px;
}
@media only screen and (max-width: 767px) {
  .work-at-container__block-tile-content-container-paragraph {
    font-size: 13px;
    line-height: 24px;
  }
}
.work-at-container__block-tile-content-container-image {
  width: 40px;
  height: 40px;
}
.work-at-container__block-tile-content-container-image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.work-at-container__block-tile-content-container-redirect {
  position: relative;
}
.work-at-container__block-tile-content-container-redirect .arrow-right {
  position: absolute;
  top: 0;
  right: 5px;
  display: block;
  margin-left: 10px;
  transform: rotate(45deg);
}
@media only screen and (max-width: 767px) {
  .work-at-container__block-tile-content-container-redirect .arrow-right {
    right: -25px;
    margin-left: 18px;
  }
}
.work-at-container__block-tile-content-container-redirect .arrow-right::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: inherit;
  content: "\f103";
  display: inline-block;
  font-size: 18px;
  position: relative;
  vertical-align: middle;
  color: #fe7000;
}
.work-at-container__block-tile-content-container-link {
  display: block;
  text-transform: capitalize;
  text-align: right;
  margin-top: 36px;
  margin-right: 35px;
  white-space: nowrap;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .work-at-container__block-tile-content-container-link {
    text-align: left;
  }
}
@media only screen and (max-width: 767px) {
  .work-at-container__block-tile-content-container-link {
    margin-right: 12px;
    margin-top: 25px;
  }
}
.work-at-container__block-tile-content-container-link, .work-at-container__block-tile-content-container-title {
  text-overflow: ellipsis;
  overflow: hidden;
}
.work-at-container__main-title, .work-at-container__title-opposite {
  font-size: 48px;
  line-height: 50px;
  font-family: "HeadingProTreble-Heavy", sans-serif;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .work-at-container__main-title, .work-at-container__title-opposite {
    font-size: 30px;
    line-height: 36px;
  }
}
.work-at-container__main-title {
  color: transparent;
  display: block;
  margin-bottom: 2px;
  -webkit-text-stroke: 1px #2d2d2d;
}
@media only screen and (max-width: 767px) {
  .work-at-container__main-title {
    margin-bottom: 0;
  }
}
.work-at-container__title-opposite {
  display: block;
  color: #2d2d2d;
}
.work-at-container__block-tile-image {
  width: 100%;
  height: 385px;
}
@media only screen and (max-width: 767px) {
  .work-at-container__block-tile-image {
    height: 365px;
  }
}
.work-at-container__block-tile-image img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .work-at-container__block-tile-image img {
    object-position: top;
  }
}
.work-at-container__prev-btn, .work-at-container__next-btn {
  outline: none;
}
@media only screen and (min-width: 768px) {
  .work-at-container__prev-btn, .work-at-container__next-btn {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .work-at-container__prev-btn, .work-at-container__next-btn {
    background-image: url("../../../img/svg/arrow-down.svg");
  }
}
@media only screen and (max-width: 767px) {
  .work-at-container__prev-btn {
    left: 0;
    transform: rotate(90deg);
  }
}
@media only screen and (max-width: 767px) {
  .work-at-container__next-btn {
    right: 0;
    transform: rotate(-90deg);
  }
}
.work-at-container .video-block .video-wrapper {
  padding: 30px 0 0;
}
@media only screen and (min-width: 1025px) {
  .work-at-container .video-block .video-wrapper {
    padding: 60px 0 0;
  }
}
.work-at-container .video-block .video-inner {
  width: 100%;
}
.work-at-container .video-block .custom-video video {
  width: 100%;
}
.work-at-container.video-active .work-at-container__block-tile-image {
  height: 0;
}
.work-at-container.video-active .work-at-container__block-tile-content-container {
  width: 100%;
  max-width: 100%;
  margin-top: 0;
}
@media only screen and (max-width: 1024px) {
  .work-at-container.video-active .work-at-container__block-tile {
    flex: 0 0 22%;
  }
}
@media only screen and (max-width: 767px) {
  .work-at-container.video-active .work-at-container__block-tile {
    flex: none;
    margin-right: 0;
  }
}
.work-at-container.video-active .work-at-container__block-tile-content-container-redirect {
  display: none;
}
@media only screen and (max-width: 767px) {
  .work-at-container.video-active .work-at-container__slider {
    position: relative;
  }
}
@media only screen and (max-width: 767px) {
  .work-at-container.video-active .work-at-container__prev-btn {
    left: -24px;
  }
}
@media only screen and (max-width: 767px) {
  .work-at-container.video-active .work-at-container__next-btn {
    right: -24px;
  }
}