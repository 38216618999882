// older swiper navigation css
// needed for overruling ~swiper/modules/navigation.min when an older styled swiper needs to look the same as before the update from version: 4.5.1 to 11.0.7
.swiper-button-next::after,
.swiper-rtl .swiper-button-prev::after,
.swiper-button-prev::after,
.swiper-rtl .swiper-button-next::after {
    content: none;
}

.swiper-button-next,
.swiper-button-prev {
    position: absolute;
    top: 50%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    background-size: 27px 44px;
    background-position: 50%;
    background-repeat: no-repeat;
}

.swiper-container {
    position: relative;
    z-index: 1;
}
// END older swiper navigation css
