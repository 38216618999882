// Node_modules swiper:

@use "swiper/modules/navigation.min.css";

@import "./video-block";
@import "../../05-components/swiper-navigation-legacy";
// older swiper navigation css for this page only:
.work-at-container__slider {
    position: static;
}
// END older swiper navigation css

.work-at-container {
    max-width: 1280px;
    padding-top: 80px;
    padding-bottom: 70px;
    margin: 0 auto;

    @include mq($until-dt-max) {
        padding-left: 25px;
        padding-right: 25px;
    }

    @include mq($tablet-max) {
        padding-top: 80px;
        padding-bottom: 30px;
    }

    @include mq($mobile-max) {
        position: relative;
        overflow: hidden;
    }

    &__block {
        display: flex;
        flex-wrap: wrap;
        margin-top: 56px;

        @include mq($mobile-max) {
            flex-wrap: nowrap;
            padding: 0;
            margin-top: 27px;
        }

        .work-at-container__block-tile {
            &:last-child {
                @include mq($mobile-max) {
                    margin-bottom: 33px;
                }
            }

            &:nth-child(4n + 4) {
                margin-right: 0;
            }
        }
    }

    &__block-tile {
        position: relative;
        flex: 0 0 24%;
        margin-right: 1.3%;
        margin-bottom: 30px;

        @include mq($tablet-max) {
            flex: 0 0 23%;
            margin-right: 2.65%;
        }

        @include mq($mobile-max) {
            flex: none;
            margin-right: 0;
            margin-bottom: 35px;
        }
    }

    &__link {
        background-color: $safety-orange;
        color: $white;
        @include body;

        font-family: $heading-pro-treble-heavy;
        display: block;
        text-align: center;
        padding: 14px 10px;
        width: 25%;

        &.center {
            margin: 25px auto 0;
        }

        @include mq($not-mobile) {
            &.left {
                margin: 25px auto 0 25px;
            }

            &.right {
                margin: 25px 25px 0 auto;
            }
        }

        @include mq($laptop-st) {
            &.left {
                margin: 25px auto 0 0;
            }

            &.right {
                margin: 25px 0 0 auto;
            }
        }

        @include mq($tablet) {
            margin-top: 155px;
        }

        @include mq($mobile-max) {
            margin: 0 auto;
            width: 90%;
            max-width: 310px;
        }
    }

    &__block-tile-content-container {
        background-color: $white;
        position: relative;
        padding: 20px;
        margin-top: -154px;
        margin-left: auto;
        width: 72%;

        @include mq($until-dt-med) {
            width: 92%;
        }

        @include mq($not-mobile) {
            max-width: 220px;
        }

        @include mq($tablet-max) {
            width: 150px;
        }

        @include mq($mobile-max) {
            padding: 20px 27px;
            margin-top: -137px;
            width: 92%;
        }
    }

    &__block-tile-content-container-title {
        display: block;
        color: $nero;
        font-family: $heading-pro-treble-heavy;
        font-size: 22px;
        line-height: 28px;
        text-transform: uppercase;
        margin: 13px 0 10px;

        @include mq($mobile-max) {
            font-size: 20px;
            line-height: 26px;
        }
    }

    &__block-tile-content-container-paragraph,
    &__block-tile-content-container-link {
        @include body;
    }

    &__block-tile-content-container-paragraph {
        @include mq($mobile-max) {
            font-size: 13px;
            line-height: 24px;
        }
    }

    &__block-tile-content-container-image {
        width: 40px;
        height: 40px;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    &__block-tile-content-container-redirect {
        position: relative;

        .arrow-right {
            position: absolute;
            top: 0;
            right: 5px;
            display: block;
            margin-left: 10px;
            transform: rotate(45deg);

            @include mq($mobile-max) {
                right: -25px;
                margin-left: 18px;
            }

            &::before {
                @include iconfont-styles;

                content: svg(arrow-right);
                display: inline-block;
                font-size: 18px;
                position: relative;
                vertical-align: middle;
                color: $safety-orange;
            }
        }
    }

    &__block-tile-content-container-link {
        display: block;
        text-transform: capitalize;
        text-align: right;
        margin-top: 36px;
        margin-right: 35px;
        white-space: nowrap;

        @include mq($tablet) {
            text-align: left;
        }

        @include mq($mobile-max) {
            margin-right: 12px;
            margin-top: 25px;
        }
    }

    &__block-tile-content-container-link,
    &__block-tile-content-container-title {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__main-title,
    &__title-opposite {
        @include h3;

        font-family: $heading-pro-treble-heavy;
        text-transform: uppercase;

        @include mq($mobile-max) {
            font-size: 30px;
            line-height: 36px;
        }
    }

    &__main-title {
        color: transparent;
        display: block;
        margin-bottom: 2px;
        -webkit-text-stroke: 1px $nero;

        @include mq($mobile-max) {
            margin-bottom: 0;
        }
    }

    &__title-opposite {
        display: block;
        color: $nero;
    }

    &__block-tile-image {
        width: 100%;
        height: 385px;

        @include mq($mobile-max) {
            height: 365px;
        }

        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;

            @include mq($mobile-max) {
                object-position: top;
            }
        }
    }

    &__prev-btn,
    &__next-btn {
        outline: none;

        @include mq($not-mobile) {
            display: none;
        }

        @include mq($mobile-max) {
            background-image: url("../../../img/svg/arrow-down.svg");
        }
    }

    &__prev-btn {
        @include mq($mobile-max) {
            left: 0;
            transform: rotate(90deg);
        }
    }

    &__next-btn {
        @include mq($mobile-max) {
            right: 0;
            transform: rotate(-90deg);
        }
    }

    .video-block {
        .video-wrapper {
            padding: 30px 0 0;

            @include mq($not-mobile-tablet) {
                padding: 60px 0 0;
            }
        }

        .video-inner {
            width: 100%;
        }

        .custom-video video {
            width: 100%;
        }
    }

    &.video-active {
        .work-at-container__block-tile-image {
            height: 0;
        }

        .work-at-container__block-tile-content-container {
            width: 100%;
            max-width: 100%;
            margin-top: 0;
        }

        .work-at-container__block-tile {
            @include mq($tablet-max) {
                flex: 0 0 22%;
            }

            @include mq($mobile-max) {
                flex: none;
                margin-right: 0;
            }
        }

        .work-at-container__block-tile-content-container-redirect {
            display: none;
        }

        .work-at-container__slider {
            @include mq($mobile-max) {
                position: relative;
            }
        }

        .work-at-container__prev-btn {
            @include mq($mobile-max) {
                left: -24px;
            }
        }

        .work-at-container__next-btn {
            @include mq($mobile-max) {
                right: -24px;
            }
        }
    }
}
